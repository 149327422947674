import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintenance-board',
  templateUrl: './maintenance-board.component.html',
  styleUrls: ['./maintenance-board.component.css']
})
export class MaintenanceBoardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
