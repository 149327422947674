import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Language } from 'angular-l10n';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { map } from 'rxjs/operators';
import { LoggerService } from '@app/shared/services/logger/logger.service';
import { OperationsService } from '@app/shared/services/routes/operations.service';
import * as moment from 'moment';
import * as _ from 'lodash';

/* Chart Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-charting-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.css']
})
export class ChartingAvailabilityComponent implements OnInit {

  operations: any;
  logger: any;

  constructor(public operationsService: OperationsService, public loggerService: LoggerService) {
  }

  ngOnInit() {

    let colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.4;

    this.operationsService.getItemsList(moment().format('YYYYMMDD')).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map((a: any) => ({
            key: a.key,
            active: a.payload.val().vehicle !== "" ? true : false,
            category: a.payload.val().vehicle !== "" ? a.payload.val().vehicle : null,
            start: moment().hour(a.payload.val().time.substring(0, 2)).minutes(a.payload.val().time.substring(3, 5)).format('YYYY-MM-DD HH:mm'),
            end: a.payload.val().task_in && a.payload.val().task_out ? ( a.payload.val().vehicleType === "Camioneta" ? moment().hour(a.payload.val().time.substring(0, 2)).minutes(a.payload.val().time.substring(3, 5)).add(a.payload.val().dhours_sm, 'hours').format('YYYY-MM-DD HH:mm'): moment().hour(a.payload.val().time.substring(0, 2)).minutes(a.payload.val().time.substring(3, 5)).add(a.payload.val().dhours, 'hours').format('YYYY-MM-DD HH:mm')) : (a.payload.val().vehicleType === "Camioneta" ? moment().hour(a.payload.val().time.substring(0, 2)).minutes(a.payload.val().time.substring(3, 5)).add(a.payload.val().hours_sm, 'hours').format('YYYY-MM-DD HH:mm') : moment().hour(a.payload.val().time.substring(0, 2)).minutes(a.payload.val().time.substring(3, 5)).add(a.payload.val().hours, 'hours').format('YYYY-MM-DD HH:mm')),
            color: colorSet.getIndex(0).brighten(0),
            task: a.payload.val().customer,
            route: a.payload.val().name,
            additional: { ...a.payload.val() }
          }))
        )
      ).subscribe((items: any) => {
        this.operations = _(items)
        .filter( (i) => { return i.active})
        .sortBy('category')
        .value();
        /* this.operations = _.filter(items, function (i) {
          return i.active;
        }) */

        let chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.paddingRight = 30;
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";



        chart.data = this.operations;
        console.log(this.operations);
        /* chart.data = [{
          "category": "106",
          "start": "2019-01-22 01:15",
          "end": "2019-01-22 13:10",
          "color": colorSet.getIndex(0).brighten(0),
          "task": "02:30 horas disponible y algo más de información"
        }, {
          "category": "106",
          "start": "2019-01-22 19:00:00",
          "end": "2019-01-22 21:00:00",
          "color": colorSet.getIndex(0).brighten(0),
          "task": "03:00 horas y algo más de información"
        }, {
          "category": "107",
          "start": "2019-01-22 00:00",
          "end": "2019-01-22 03:10",
          "color": colorSet.getIndex(0).brighten(0),
          "task": "02:30 horas disponible y algo más de información"
        }, {
          "category": "107",
          "start": "2019-01-22 11:55:00",
          "end": "2019-01-22 14:23:00",
          "color": colorSet.getIndex(0).brighten(0),
          "task": "03:00 horas y algo más de información"
        }, {
          "category": "109",
          "start": "2019-01-22 22:15",
          "end": "2019-01-22 23:59",
          "color": colorSet.getIndex(0).brighten(0),
          "task": "02:30 horas disponible y algo más de información"
        }, {
          "category": "109",
          "start": "2019-01-22 16:03:00",
          "end": "2019-01-22 17:48:00",
          "color": colorSet.getIndex(0).brighten(0),
          "task": "03:00 horas y algo más de información"
        }];
     */
        chart.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm";
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        //categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.fontSize = "10";
        categoryAxis.renderer.minGridDistance = 10;

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.tooltip.dateFormatter.dateFormat = "HH:mm";
        /* dateAxis.renderer.ticks.template.disabled = true;
dateAxis.renderer.grid.template.strokeDasharray = "3,3";
dateAxis.renderer.grid.template.strokeOpacity = 0.2;
dateAxis.renderer.line.disabled = true;
dateAxis.renderer.inside = false;
dateAxis.renderer.labels.template.fillOpacity = 0.4;
dateAxis.renderer.minLabelPosition = 0.03;
dateAxis.renderer.labels.template.fill = am4core.color("#ffffff");
dateAxis.renderer.grid.template.stroke = am4core.color("#ffffff"); */

        dateAxis.renderer.minGridDistance = 30;
        dateAxis.dateFormats.setKey("minute", "hh:mm a");
        dateAxis.baseInterval = { count: 15, timeUnit: "minute" };
        dateAxis.min = moment().startOf('day').valueOf(),
        dateAxis.max = moment().endOf('day').valueOf(),
        dateAxis.strictMinMax = true;
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.renderer.fontSize = "10";
        let series1 = chart.series.push(new am4charts.ColumnSeries());
        series1.columns.template.width = am4core.percent(90);
        series1.columns.template.tooltipText = `[bold]Asignación
         Unidad: {category}
         Ruta: {route} 
         Cliente: {task}
         Tiempo: De [bold]{openDateX}[/] a [bold]{dateX}[/]`;
        series1.dataFields.openDateX = "start";
        series1.dataFields.dateX = "end";
        series1.dataFields.categoryY = "category";
        series1.columns.template.propertyFields.fill = "color"; // get color from data
        series1.columns.template.propertyFields.stroke = "color";
        series1.columns.template.strokeOpacity = 1;

        let range = dateAxis.createSeriesRange(series1);
        range.date = new Date(2019, 0, 21, 24, 0, 0, 0);
        range.endDate = new Date(2019, 0, 22, 24, 0, 0, 0);

        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarY = new am4core.Scrollbar();

      })

  }

}
