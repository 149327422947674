import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChartingAvailabilityComponent } from '@app/shared/components/charting/availability/availability.component';
import { ChartingWorkordersComponent } from '@app/shared/components/charting/workorders/workorders.component';
import { ChartingOperationsComponent } from '@app/shared/components/charting/operations/operations.component';
import { ChartingMaintenanceComponent } from '@app/shared/components/charting/maintenance/maintenance.component';
import { FullFooterComponent } from '@app/shared/components/footers/full/full.component';
import { PageNotFoundComponent } from '@app/shared/components/notfound/page-not-found/page-not-found.component';
import { SimpleHeaderComponent } from '@app/shared/components/headers/simple/simple.component';
import { SystemFeaturesComponent } from '@app/shared/components/system/features/features.component';
import { TransparentHeaderComponent } from '@app/shared/components/headers/transparent/transparent.component';
import { MaintenanceBoardComponent } from '@app/shared/components/maintenance-board/maintenance-board.component';
import { ReportsOperationsYesterdayComponent } from './components/reports/operations/yesterday/yesterday.component';

//L10n support
import { LocalizationModule, LocaleValidationModule } from 'angular-l10n';

// AMCharts
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

// Importing themes
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// Importing translations
import am4lang_es_MX from "@amcharts/amcharts4/lang/lt_LT";
import { TeamsComponent } from '@app/shared/components/teams/teams.component';

//Services
import { FleetService } from '@app/shared/services/fleet/fleet.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';

//AG-Grid
import "ag-grid-enterprise";
import { AgGridModule } from "ag-grid-angular";
import { LicenseManager } from "ag-grid-enterprise";
import { AvailabilityMaintenanceComponent } from './components/charting/availability-maintenance/availability-maintenance.component';
LicenseManager.setLicenseKey("Evaluation_License_Valid_Until_29_August_2018__MTUzNTQ5NzIwMDAwMA==00c7d5ad3b64cd638c04ea5496156d8b");

@NgModule({
  imports: [
    AgGridModule.withComponents([]),
    CommonModule,
    FormsModule,
    LocalizationModule,
    LocaleValidationModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    FullFooterComponent,
    PageNotFoundComponent,
    SimpleHeaderComponent,
    TeamsComponent,
    TransparentHeaderComponent,
    TeamsComponent,
    ChartingMaintenanceComponent,
    ChartingWorkordersComponent,
    SystemFeaturesComponent,
    MaintenanceBoardComponent,
    ChartingOperationsComponent,
    ReportsOperationsYesterdayComponent,
    ChartingAvailabilityComponent,
    AvailabilityMaintenanceComponent
  ],
  exports: [
    AgGridModule,
    AvailabilityMaintenanceComponent,
    ChartingAvailabilityComponent,
    ChartingMaintenanceComponent,
    ChartingWorkordersComponent,
    ChartingOperationsComponent,
    CommonModule, 
    FormsModule, 
    FullFooterComponent,
    LocalizationModule,
    LocaleValidationModule,
    ReactiveFormsModule,
    SimpleHeaderComponent,
    TeamsComponent,
    TransparentHeaderComponent,
    RouterModule,
    SystemFeaturesComponent,
    ReportsOperationsYesterdayComponent
  ],
  providers: [
    FleetService,
    AnalyticsService
  ]
})

export class SharedModule {}