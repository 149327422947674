import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Language } from 'angular-l10n';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { map } from 'rxjs/operators';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-charting-workorders',
  templateUrl: './workorders.component.html',
  styleUrls: ['./workorders.component.css']
})
export class ChartingWorkordersComponent implements OnInit, OnDestroy {

  private chart: am4charts.XYChart;
  charting = [];
  @Language() lang: string;

  constructor(
    private zone: NgZone,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {

    this.zone.runOutsideAngular(() => {

      let chart = am4core.create("chartdiv", am4charts.PieChart);

      this.analyticsService.getChartingData("workorders").snapshotChanges()
        .pipe(
          map(actions =>
            actions.map(a => ({ key: a.key, ...a.payload.val(), count: a.payload.numChildren() }))
          )
        )
        .subscribe(items => {
          chart.data = items;
        });

      chart.radius = am4core.percent(70);
      chart.innerRadius = am4core.percent(40);
      chart.startAngle = 180;
      chart.endAngle = 360;

      let series = chart.series.push(new am4charts.PieSeries());
      series.dataFields.value = "count";
      series.dataFields.category = "key";

      series.slices.template.cornerRadius = 10;
      series.slices.template.innerCornerRadius = 7;
      series.slices.template.draggable = false;
      series.slices.template.inert = true;

      /* series.slices.template.events.on("doublehit", function(ev) {
        console.log("clicked on ", ev.target);
      }, this); */

      series.hiddenState.properties.startAngle = 90;
      series.hiddenState.properties.endAngle = 90;

      chart.legend = new am4charts.Legend();
    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
