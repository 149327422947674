import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { LocaleService, TranslationService, Language } from 'angular-l10n';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})

export class UserProfileComponent implements OnInit {

  @Language() lang: string;
  title: string;

  constructor( public auth: AuthService, public locale: LocaleService, public translation: TranslationService ) {

  }

  ngOnInit(){
    
  }

}
