import { NgModule } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { LocalizationModule, LocaleValidationModule } from 'angular-l10n';
import { Roles, User } from '@app/core/interfaces/user';

@NgModule({
  imports: [
    AngularFireAuthModule,
    AngularFireDatabaseModule
  ],
  providers: [
    AuthService
  ],
  exports: [
    LocalizationModule,
    LocaleValidationModule
  ]
})
export class CoreModule { }