import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LocaleService, TranslationService, Language } from 'angular-l10n';
import { Location, PopStateEvent } from "@angular/common";
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {

  @Language() lang: string;
  title: string;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  constructor(private location: Location, private router:Router, public locale: LocaleService, public translation: TranslationService) {

  }

  ngOnInit() {
    this.translation.translationChanged()
      .subscribe(() => {
        this.title = this.translation.translate('Title');
      })

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else
          window.scrollTo(0, 0);
      }
    });
  }

  selectLanguage(language: string): void {
    this.locale.setCurrentLanguage(language);
  }
}
