import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireObject } from 'angularfire2/database';
import { of, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from '../interfaces/user';

@Injectable()
export class AuthService {

  user$: Observable<any>;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFireDatabase,
    private router: Router
  ) {
    this.user$ = this.afAuth.authState
      .pipe(
        switchMap(user => {
          if (user) {
            return this.afs.object(`/catalog/users/${user.uid}`).valueChanges()
          } else {
            return of(null)
          }
        })
      )
  }

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
        .signInWithPopup(provider)
        .then(res => {
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        })
    })
  }

  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.auth
        .signInWithPopup(provider)
        .then(res => {
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        })
    })
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth
        .signInWithPopup(provider)
        .then(res => {
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        })
    })
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
        .then(res => {
          resolve(res);
        }, err => reject(err))
    })
  }

  doLogin(value) {
    return firebase.auth().signInWithEmailAndPassword(value.email, value.password)
      .then((user) => {
        return user;
      })
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.auth.signOut()
        resolve();
      }
      else {
        reject();
      }
    });
  }

  private updateUserData(user) {
    // Sets user data to firebase on login
    const userRef: AngularFireObject<any> = this.afs.object(`/catalogs/users/${user.uid}`);
    const data: User = {
      id: user.uid,
      uid: user.uid,
      email: user.email,
      roles: {
        subscriber: true
      }
    }
    return userRef.set(data);
  }

  canRead(user: User, module?: string): boolean {
    const allowed = ['payroll','admin','management'];
    return this.checkAuthorization(user, allowed)
  }

  canEdit(user: User, module?: string): boolean {
    const allowed = ['payroll','admin','management'];
    return this.checkAuthorization(user, allowed)
  }

  canDelete(user: User, module?: string): boolean {
    const allowed = ['payroll','admin','management'];
    return this.checkAuthorization(user, allowed)
  }

  canCreate(user: User, module?: string): boolean {
    let allowed = ['payroll','admin','management'];
    return this.checkAuthorization(user, allowed)
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false
    if (!user.roles) return false
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true
      }
    }
    return false
  }

}