import { Component, OnInit, OnDestroy } from '@angular/core';
import { Language } from 'angular-l10n';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { map } from 'rxjs/operators';
declare var mr: any;

@Component({
  selector: 'app-charting-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.css']
})
export class ChartingOperationsComponent implements OnInit, OnDestroy {

  @Language() lang: string;

  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    mr.documentReady()
  }

  ngOnDestroy() {
    
  }

}
