import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Language } from 'angular-l10n';
import { map } from 'rxjs/operators';
import { LoggerService } from '@app/shared/services/logger/logger.service';
import { MaintenanceService } from '@app/shared/services/maintenance/maintenance.service';
import * as moment from 'moment';
import * as _ from 'lodash';

/* Chart Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-charting-availability-maintenance',
  templateUrl: './availability-maintenance.component.html',
  styleUrls: ['./availability-maintenance.component.css']
})
export class AvailabilityMaintenanceComponent implements OnInit {

  operations: any;
  logger: any;

  constructor(public maintenanceService: MaintenanceService, public loggerService: LoggerService) {
  }

  ngOnInit() {

    let colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.4;
    moment.locale("es");
    this.maintenanceService.getItemsList().snapshotChanges()
      .pipe(
        map(actions =>
          actions.map((a: any) => ({
            key: a.key,
            active: a.payload.val().vehicle !== "" ? true : false,
            category: a.payload.val().vehicle !== "" ? a.payload.val().vehicle : null,
            start: moment(a.payload.val().date).format('YYYY-MM-DD HH:mm'),
            end: typeof a.payload.val().eta != "undefined" && a.payload.val().eta != "" ? moment(a.payload.val().date).add(a.payload.val().eta, 'hours').format('YYYY-MM-DD HH:mm') : moment(a.payload.val().date).add(2, 'hours').format('YYYY-MM-DD HH:mm'),
            color: colorSet.getIndex(0).brighten(0),
            task: a.payload.val().serviceType,
            calendar: a.payload.val().eta != "" ? moment(a.payload.val().date).add(a.payload.val().eta, 'hours').fromNow() : moment(a.payload.val().date).add(2, 'hours').fromNow(),
            route: a.payload.val().status,
            additional: { ...a.payload.val() }
          }))
        )
      )
      .subscribe(items => {

        let chart = am4core.create("chartdivPM", am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.paddingRight = 30;
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";

        chart.data = items;
        console.log(items);

        chart.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm";
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        //categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.fontSize = "10";
        categoryAxis.renderer.minGridDistance = 10;

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        // dateAxis.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm";
        dateAxis.tooltip.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm";
        /* dateAxis.renderer.ticks.template.disabled = true;
dateAxis.renderer.grid.template.strokeDasharray = "3,3";
dateAxis.renderer.grid.template.strokeOpacity = 0.2;
dateAxis.renderer.line.disabled = true;
dateAxis.renderer.inside = false;
dateAxis.renderer.labels.template.fillOpacity = 0.4;
dateAxis.renderer.minLabelPosition = 0.03;
dateAxis.renderer.labels.template.fill = am4core.color("#ffffff");
dateAxis.renderer.grid.template.stroke = am4core.color("#ffffff"); */

        // dateAxis.renderer.minGridDistance = 30;
        // dateAxis.dateFormats.setKey("minute", "hh:mm a");
        // dateAxis.baseInterval = { count: 15, timeUnit: "minute" };
        // dateAxis.min = moment().startOf('day').valueOf(),
        // dateAxis.max = moment().endOf('day').valueOf(),
        // dateAxis.strictMinMax = true;
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.renderer.fontSize = "10";
        let series1 = chart.series.push(new am4charts.ColumnSeries());
        series1.columns.template.width = am4core.percent(90);
        series1.columns.template.tooltipText = `[bold]{task}
         Fecha: [bold]{openDateX}[/]
         Unidad: {category}
         Status: {route}
         Duración: {additional.eta} horas
         ETA: [bold]{calendar}[/]`;
        series1.dataFields.openDateX = "start";
        series1.dataFields.dateX = "end";
        series1.dataFields.categoryY = "category";
        series1.columns.template.propertyFields.fill = "color"; // get color from data
        series1.columns.template.propertyFields.stroke = "color";
        series1.columns.template.strokeOpacity = 1;

        let range = dateAxis.createSeriesRange(series1);
        // range.date = new Date(2019, 0, 21, 24, 0, 0, 0);
        // range.endDate = new Date(2019, 0, 22, 24, 0, 0, 0);

        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarY = new am4core.Scrollbar();

      })

  }

}
