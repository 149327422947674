import { Component, OnInit } from '@angular/core';
import { Language } from 'angular-l10n';

@Component({
  selector: 'app-full-footer',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.css']
})

export class FullFooterComponent implements OnInit {

  @Language() lang: string;

  constructor() { }

  ngOnInit() {
  }

}
