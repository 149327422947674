// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  fbConfig: {
    viabus: {
      apiKey: "AIzaSyARnE9g_U_q7TylWBYF8-Q4oZg_uuIe41s",
      authDomain: "ipcar-d2f8a.firebaseapp.com",
      databaseURL: "https://ipcar-d2f8a.firebaseio.com",
      projectId: "ipcar-d2f8a",
      storageBucket: "ipcar-d2f8a.appspot.com",
      messagingSenderId: "259829907819"
    },
    milan: {
      apiKey: "AIzaSyAxLU6aekC4wcBM9icp0-oQLUQmBOS-O6o",
      authDomain: "fir-bus-8b082.firebaseapp.com",
      databaseURL: "https://fir-bus-8b082.firebaseio.com",
      projectId: "fir-bus-8b082",
      storageBucket: "fir-bus-8b082.appspot.com",
      messagingSenderId: "574291803488"
    }
  },
  active: 'milan', // Changes per customer
  custom: {
    viabus: {
      name: "Viabus"
    },
    milan: {
      name: "Autotransportes Milán"
    }
  },
  onemap: {
    milan: { 
      url: "http://milan.internet7.mx",
      authorization: "Basic ZXZhbGxnYXJAaG90bWFpbC5jb206YWRtaW4="
    },
    viabus: {
      url: "http://viabus.internet7.mx",
      authorization: "Basic ZXZhbGxnYXJAaG90bWFpbC5jb206YWRtaW4="
    }
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

