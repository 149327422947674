export class Item {
  name: string = '';
  lastname: string = '';
  taxid: string = '';
  curp: string = '';
  ssn: string = '';
  email: string = '';
  phone: string = '';
  ssn_date: string = '';
  address: Address;
  contact: Contact;
  type: string;
  job_info: JobInfo;
  fines_info: [Fines];
  movements: [Movements];
  attributes: [Attributes];
  active: boolean = true;
}

export class Address {
  type: string = "Personal";
  address: string = '';
  suite: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  country: string = '';

}

export class Contact {
  name: string = '';
  type: string = '';
  phone: string = '';
  mobile: string = '';
}

export class JobInfo {
  id: string = '';
  position: string = '';
  payroll: string = '';
  net_payroll: string = '';
  ss_payroll: string = '';
  infonavit: string = '';
  child_support: string = '';
  savings_fund: string = '';
  vacations: string = '';
  vacations_prime: string = '';
  license_driver_type: string = '';
  license_driver_expiry: string = '';
  license_driver: string = '';
  performance_reward: string = '';
  assistance_reward: string = '';
  noreports_reward: string = '';
  best_reward: string = '';
  pnr_reward: string = '';
  network_cellphone: string = '';
  cellphone: string = '';
}

export class Fines {
  datetime: string = '';
  inmmediate: string = '';
  fine_reason: string = '';
  fine: string = '';
  notes: string = '';
}

export class Movements {
  datetime: string = '';
  actual_position: string = '';
  new_position: string = '';
  movement: string = '';
  movement_reason: string = '';
  notes: string = '';
}

export class Attributes {
  attribute: string = '';
  value: string = '';
}

export var PersonnelTypes = [
  "Almacen",
  "Auxiliar Administrativo",
  "Caseta",
  "Cobranza",
  "Compras",
  "Controladores",
  "Dirección",
  "Facturación",
  "Gerencia",
  "Instructor",
  "Jefe de Taller",
  "Jefe de Operaciones",
  "Monitoreo",
  "Nómina",
  "Operador",
  "Rendimientos",
  "Rolador",
  "Soporte",
  "Supervisión",
  "Taller",
  "Tráfico",
  "Ventas",
]

export var RolesDefinitions = [
  { role: "warehouse", name: "Almacen" },
  { role: "adminaux", name: "Auxiliar Administrativo" },
  { role: "toll", name: "Caseta" },
  { role: "collect", name: "Cobranza" },
  { role: "purchases", name: "Compras" },
  { role: "controller", name: "Controladores" },
  { role: "management", name: "Dirección" },
  { role: "invoicing", name: "Facturación" },
  { role: "manager", name: "Gerencia" },
  { role: "instructor", name: "Instructor" },
  { role: "shopmanager", name: "Jefe de Taller" },
  { role: "operationsmanager", name: "Jefe de Operaciones" },
  { role: "monitoring", name: "Monitoreo" },
  { role: "payroll", name: "Nómina" },
  { role: "driver", name: "Operador" },
  { role: "performance", name: "Rendimientos" },
  { role: "rolling", name: "Rolador" },
  { role: "support", name: "Soporte" },
  { role: "supervisor", name: "Supervisión" },
  { role: "mechanic", name: "Taller" },
  { role: "traffic", name: "Tráfico" },
  { role: "sales", name: "Ventas" }
]

export var columnDefs = [
  { headerName: 'Id', field: '$key', hide: true },
  { headerName: "#", checkboxSelection: true, suppressSorting: true },
  { headerName: 'No Empleado', field: 'job_info.id' },
  {
    headerName: 'Activo', field: 'active', editable: true, enableRowGroup: true, filter: 'text',
    cellRenderer: function (params) {
      return params.value === true ? "Si" : "No";
    },
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: function (params) {
      var allowedOptions = [true, false];
      return {
        values: allowedOptions,
        formatValue: function (value) {
          return value ? "Si" : "No";
        }
      };
    }
  },
  {
    headerName: 'Rol', field: 'type', enableRowGroup: true, editable: true,
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      values: PersonnelTypes
    }
  },
  { headerName: 'RFC', field: 'taxid', editable: true },
  { headerName: 'NSS', field: 'ssn', editable: true },
  { headerName: 'Nombre(s)', field: 'name', editable: true },
  { headerName: 'Apellido(s)', field: 'lastname', editable: true, sort: 'asc' },
  {
    headerName: 'Género', field: 'gender', enableRowGroup: true, editable: true,
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      values: ['Femenino', 'Masculino']
    }
  },
  { headerName: 'Teléfono', field: 'phone', editable: true },
  { headerName: 'Cuenta Correo', field: 'email', editable: true },
  { headerName: 'Dirección', field: 'address.address' },
  { headerName: 'Colonia', field: 'address.address2' },
  { headerName: 'Numero', field: 'address.suite' },
  { headerName: 'Puesto', field: 'job_info.position', enableRowGroup: true },
  { headerName: 'Contacto Emergencia', field: 'contact.name' },
  { headerName: 'Teléfono contacto', field: 'contact.phone' },
  { headerName: 'Celular contacto', field: 'contact.mobile' },
]

export var rowGroupPanelShow = "always";