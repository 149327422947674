import { PersonnelTypes } from '@app/shared/models/employee';

export class FirebaseUserModel {
  image: string;
  name: string;
  provider: string;

  constructor(){
    this.image = "";
    this.name = "";
    this.provider = "";
  }
}

export class Item {
  $key: string = '';
  role: string = '';
  displayName: string = '';
  email: string = '';
  timeStamp: number = 0;
  notes: string = '';
  active: boolean = true;
  photoUrl: string = '';
}

export var columnDefs = [
  { headerName: 'Id', field: '$key', hide: true },
  { headerName: "#", checkboxSelection: true, suppressSorting: true },
  { headerName: 'Nombre', field: 'displayName', editable: true, sort: 'asc' },
  { headerName: 'Email', field: 'email' },
  { headerName: 'Rol', field: 'role', editable: true, enableRowGroup: true,
    cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: PersonnelTypes
      } 
  },
  { headerName: 'Activo', field: 'active', editable: true, enableRowGroup: true,
  cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: [true,false]
      } ,
  cellRenderer: function(params) {
    let icon = typeof(params.value) != "undefined" && params.value === true ? "check_circle" : "cancel";
    let color = typeof(params.value) != "undefined" && params.value === true ? "color--success" : "color--dark";
    let cellContent: string = '';
    cellContent = `<i class="material-icons ${color}">${icon}</i>`;
    return cellContent;
  }
 }
]

export var rowGroupPanelShow = "always";