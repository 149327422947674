import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleService, Language } from 'angular-l10n';
import * as firebase from 'firebase';
import { AuthService } from '@app/core/services/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';

import { ConfigService } from '@app/shared/services/systemconfig/config.service';
import { Item } from '@app/shared/services/systemconfig/config';

declare var mr: any;

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.css']
})

export class SimpleHeaderComponent implements OnInit {

  @Language() lang: string;
  user: Observable<firebase.User>;
  companyRef$ = 'company';
  company: Observable<Item>;

  constructor( private configService: ConfigService, private router:Router, private afAuth: AngularFireAuth, private authService: AuthService, public locale: LocaleService ) { 
    this.user = this.afAuth.authState;
  }

  ngOnInit() {
    mr.documentReady();
    this.company = this.configService.getItem(this.companyRef$).valueChanges();
  }

  selectLanguage(language: string): void {
    this.locale.setCurrentLanguage(language);
  }

  logout() {
    this.afAuth.auth.signOut().then( () => {
      this.router.navigate(['/login']);
    })
  }

  login() {
      this.router.navigate(['/login']);
  }

}
