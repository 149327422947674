import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleService, TranslationService, Language } from 'angular-l10n';
import { CoreModule } from '@app/core/core.module';
import * as firebase from 'firebase';
import { AuthService } from '@app/core/services/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';
declare var mr: any;

@Component({
  selector: 'app-simple',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.css']
})
export class SimpleLayoutComponent implements OnInit {

  @Language() lang: string;
  user: Observable<firebase.User>;

  constructor( private router:Router, private afAuth: AngularFireAuth, private authService: AuthService, public locale: LocaleService, public translation: TranslationService) { 
    this.user = this.afAuth.authState;
  }

  ngOnInit() {
  }

  selectLanguage(language: string): void {
    this.locale.setCurrentLanguage(language);
  }

  logout(){
    this.afAuth.auth.signOut();
    this.router.navigate(['/login']);
  }

}
