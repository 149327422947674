import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { UserService } from './user.service';

@Injectable()
export class IsLoggedInResolver {

  constructor(public userService: UserService, private router: Router) { }

  resolve(): void {
    this.userService.getCurrentUser()
      .then(res => {
        this.router.navigate(['/main']);
        // return resolve(user);
      }, err => {
        console.log("user is not logged in");
        // return reject(err);
      })
  }
}