import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Language } from 'angular-l10n';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { map } from 'rxjs/operators';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-charting-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})

export class ChartingMaintenanceComponent implements OnInit, OnDestroy {

  private chart: am4charts.XYChart;
  charting = [];
  @Language() lang: string;

  constructor(
    private zone: NgZone,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {

   this.zone.runOutsideAngular(() => {

      let chart = am4core.create("chartdivm", am4charts.XYChart);

       this.analyticsService.getChartingData("pmorders").snapshotChanges()
        .pipe(
          map(actions =>
            actions.map(a => ({ key: a.key, ...a.payload.val(), count: a.payload.numChildren() }))
          )
        )
        .subscribe(items => {
          chart.data = items;
        });

      chart.colors.step = 2;
      chart.padding(30, 30, 10, 30);
      chart.legend = new am4charts.Legend()

      let workOrdersAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      workOrdersAxis.dataFields.category = "workOrders";
      workOrdersAxis.renderer.grid.template.disabled = true;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.strictMinMax = true;
      valueAxis.calculateTotals = true;
      valueAxis.renderer.minWidth = 50;

      let realTime = chart.series.push(new am4charts.ColumnSeries());
      realTime.columns.template.width = am4core.percent(80);
      realTime.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      realTime.name = "Tiempo real";
      realTime.dataFields.categoryX = "workOrders";
      realTime.dataFields.valueY = "realTime";
      realTime.dataItems.template.locations.categoryX = 0.5;
      realTime.stacked = true;
      realTime.tooltip.pointerOrientation = "vertical";
    
      let bullet1 = realTime.bullets.push(new am4charts.LabelBullet());
      bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
      bullet1.label.fill = am4core.color("#ffffff");
      bullet1.locationY = 0.5
      
      let nonPreventiveTime = chart.series.push(new am4charts.ColumnSeries());
      nonPreventiveTime.columns.template.width = am4core.percent(80);
      nonPreventiveTime.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
      nonPreventiveTime.name = "Tiempo (no mant preventivo)";
      nonPreventiveTime.dataFields.categoryX = "workOrders";
      nonPreventiveTime.dataFields.valueY = "nonPreventiveTime";
      nonPreventiveTime.dataItems.template.locations.categoryX = 0.5;
      nonPreventiveTime.stacked = true;
      nonPreventiveTime.tooltip.pointerOrientation = "vertical";

      let columnTemplate = nonPreventiveTime.columns.template;
      columnTemplate.column.tooltipText = "Series: {name}\nCategory: {categoryX}\nValue: {valueY}";
      columnTemplate.column.tooltipY = 0;
      columnTemplate.column.cornerRadiusTopLeft = 20;
      columnTemplate.column.cornerRadiusTopRight = 20;
      columnTemplate.strokeOpacity = 0;
      

      let bullet2 = nonPreventiveTime.bullets.push(new am4charts.LabelBullet());
      bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
      bullet2.locationY = 0.5;
      bullet2.label.fill = am4core.color("#ffffff");

      let authorizedTime = chart.series.push(new am4charts.LineSeries());    
      authorizedTime.dataFields.valueY = "authorizedTime";
      authorizedTime.dataFields.categoryX = "workOrders";
      authorizedTime.strokeWidth = 5;
      authorizedTime.tensionX = 0.5;
      authorizedTime.tensionY = 1;
      authorizedTime.name = "Tiempo autorizado";
      authorizedTime.stroke = am4core.color("#B22222");

      let bullet = authorizedTime.bullets.push(new am4charts.CircleBullet());
      bullet.fill = am4core.color("white");
      bullet.strokeWidth = 3;

      chart.scrollbarX = new am4core.Scrollbar();
    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}