import { Component, OnInit, Input } from '@angular/core';
import swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { TeamService } from '@app/shared/services/team/team.service';
import { Item } from '@app/shared/models/employee';
import { Language } from 'angular-l10n';

@Component({
  selector: 'app-shared-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {

  @Input('team') team;
  @Input('name') name;
  @Language() lang: string;

  objectsRef$;
  itemsAmount: any = 6;

  constructor( public itemService: TeamService ) { }

  ngOnInit() {
    this.objectsRef$ = this.itemService.getItemsList(this.team, this.itemsAmount).valueChanges();
  }

  ngAfterViewInit() {
    
  }

  private onBtnExport(){
    console.log("export clicked");
  }

}