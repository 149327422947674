import { Component, OnInit } from '@angular/core';
import { Router, Params } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { ConfigService } from '@app/shared/services/systemconfig/config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { map } from 'rxjs/operators';

declare var mr: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  
  signupForm: FormGroup;
  errorMessage: string = '';
  sub;
  company;

  constructor( private configService: ConfigService, private authService: AuthService, public fb: FormBuilder, private router:Router ) { 
    this.loadConfig()
  }

  ngOnInit() {
    
    this.signupForm = this.fb.group({
      'email': ['', [ Validators.required, Validators.email]],
      'password': ['', [ Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'), Validators.minLength(8), Validators.maxLength(25), Validators.required ]]
    });

  }

  loadConfig() {
    this.sub = this.configService.getItem('company').snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() }))
      )
      .subscribe(record => {
        console.log(record);
        this.company = record;
      });
  }

  ngAfterViewInit(){
    
  }

  doLogin(value) {

    if(this.signupForm.valid) {

      this.authService.doLogin(value)
      .then(res => {
        console.log(res);
        localStorage.setItem('userUid',res.user.uid);
        localStorage.setItem('addNewEmployee',"true");
        this.router.navigate(['/main']);
      }, err => {
        swal({
          type: 'error',
          title: 'Oops...',
          text: 'Something went wrong! ' + err.message,
          footer: '<a href>Why do I have this issue?</a>'
        })
        console.log(err);
        this.errorMessage = err.message;
      })

    }
    
  }

  get email() { return this.signupForm.get('email')}
  get password() { return this.signupForm.get('password')}

}
