import { Routes } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { UserResolver } from '@app/core/services/resolver.user';
import { IsLoggedInResolver } from '@app/core/services/resolver.islogged';

//Components
import { LoginComponent } from '@app/shared/components/registration/login/login.component';
import { MainComponent } from '@app/modules/main/main.component';
import { PageNotFoundComponent } from '@app/shared/components/notfound/page-not-found/page-not-found.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },
  { path: 'login', component: LoginComponent, resolve: { data: IsLoggedInResolver} },
  { path: 'main', component: MainComponent, resolve: { data: UserResolver} },
  { path: 'catalogs', resolve: { data: UserResolver}, loadChildren: '@app/modules/catalogs/catalogs.module#CatalogsModule'},
  { path: 'config', resolve: { data: UserResolver}, loadChildren: '@app/modules/system-config/system-config.module#SystemConfigModule'},
  { path: 'fleet', resolve: { data: UserResolver}, loadChildren: '@app/modules/fleet/fleet.module#FleetModule'},
  { path: 'maps', resolve: { data: UserResolver}, loadChildren: '@app/modules/maps/maps.module#MapsModule' },
  { path: 'reports', resolve: { data: UserResolver}, loadChildren: '@app/modules/reports/reports.module#ReportsModule' },
  { path: 'routes', resolve: { data: UserResolver}, loadChildren: '@app/modules/routes/routes.module#RoutesModule' },
  { path: 'sales', resolve: { data: UserResolver}, loadChildren: '@app/modules/sales/sales.module#SalesModule' },
  { path: 'guards', resolve: { data: UserResolver}, loadChildren: '@app/modules/guards/guards.module#GuardsModule' },
  { path: 'fuel', resolve: { data: UserResolver}, loadChildren: '@app/modules/fuel/fuel.module#FuelModule' },
  { path: 'maintenance', resolve: { data: UserResolver}, loadChildren: '@app/modules/maintenance/maintenance.module#MaintenanceModule' },
  { path: '**', component: PageNotFoundComponent }
];
