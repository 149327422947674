import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { LocaleService, TranslationService, Language } from 'angular-l10n';
import { Item } from '../../models/fleet';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  @Language() lang: string;
  
  successMessage: any = {};
  errorMessage: any = {};
  deleteWarning: any = {};

  private basePath: string = '/analytics/';

  item: AngularFireObject<Item> = null;
  items: AngularFireList<Item[]> = null;
  
  constructor(private db: AngularFireDatabase, public locale: LocaleService, private translation:TranslationService) { 
    this.translation.translationChanged().subscribe(
      () => { 
        this.successMessage.title = this.translation.translate('SuccessMessage.Title'); 
        this.successMessage.subtitle = this.translation.translate('SuccessMessage.Subtitle'); 
        this.errorMessage.title = this.translation.translate('ErrorMessage.Title'); 
        this.errorMessage.subtitle = this.translation.translate('ErrorMessage.Subtitle'); 
        this.errorMessage.footer = this.translation.translate('ErrorMessage.Footer');
        this.deleteWarning.title = this.translation.translate('DeleteWarning.Title');
        this.deleteWarning.subtitle = this.translation.translate('DeleteWarning.Subtitle');
    }
  );
  }

  getChartingData(node: string): AngularFireList<any> {
    this.items = this.db.list(this.basePath + node);
    return this.items;
  }
  
  getItemsList(items?: number): AngularFireList<Item> {
    this.items = this.db.list(this.basePath, ref => 
      ref.limitToLast(items || 1000)
    );
    return this.items
  }

  // Return a single observable item
  getItem(key: string): AngularFireObject<Item> {
    const itemPath = `${this.basePath}/${key}`;
    this.item = this.db.object(itemPath)
    return this.item
  }

  updateItem(key: string, value: any): void {
    this.items.update(key, value)
      .then(response => this.handleSuccess(response))
      .catch(error => this.handleError(error))
  }

  updateObject(value: any): void {
    this.item.update(value)
      .then(response => this.handleSuccess(response))
      .catch(error => this.handleError(error))
  }

  deleteItem(key: string, record): void {
    swal({
      title: this.deleteWarning.title,
      text: this.deleteWarning.subtitle,
      type: 'question',
      showCancelButton: true,
      confirmButtonText: `Eliminar ${record.name}`,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.items.remove(key)
          .then(response => this.handleSuccess(response))
          .catch(error => this.handleError(error))

      }
    })
  }

  // Deletes the entire list of items
  deleteAll(): void {
    this.items.remove()
      .catch(error => this.handleError(error))
  }

  // Default error handling for all actions
  handleError(error) {
    return swal({
      type: 'error',
      title: this.errorMessage.title,
      text: this.errorMessage.subtitle,
      footer: `<a routerlink=["/application/errors"]>${this.errorMessage.footer}</a>`
    })
  }

  handleSuccess(response) {
    return swal({
      timer: 3000,
      type: 'success',
      title: this.successMessage.title,
      text: this.successMessage.subtitle
    })
  }

  booleanFormatter(params) {
    return params.value === "false" ? "No" : "Si";
  }
}