import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@app/shared/services/systemconfig/config.service';
import { map } from 'rxjs/operators';

declare var mr: any;

@Component({
  selector: 'app-system-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class SystemFeaturesComponent implements OnInit {

  item: any;
  sub: any;

  constructor(
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.loadConfig();
    setTimeout( () => {
      mr.documentReady();
    }, 2000);
  }

  loadConfig() {
    this.sub = this.configService.getItem('company').snapshotChanges()
    .pipe(
      map(a => ({ key: a.key, ...a.payload.val() }))
    )
    .subscribe(record => {
      console.log(record);
      this.item = record;
    });
  }

}
