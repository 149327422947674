import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@app/core/core.module';
import { FormsModule} from '@angular/forms';

//AngularFire2
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from "angularfire2/auth";
import { AngularFireStorageModule } from 'angularfire2/storage';

//Components
import { AppComponent } from '@app/app.component';
import { UserProfileComponent } from '@app/user-profile/user-profile.component';
import { LoginComponent } from '@app/shared/components/registration/login/login.component';

//Localization
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
registerLocaleData(localeEs, 'es', localeEsExtra);

//l10n
import { L10nConfig, L10nLoader, TranslationModule, StorageStrategy, ProviderType } from 'angular-l10n';
import { l10nConfig } from '@app/configs/l10n/l10n.config';

//Routes Loading Indicator when navigating between routes
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

//Layouts
import { SimpleLayoutComponent } from '@app/shared/layouts/simple/simple.component';

// Routing
import { RouterModule } from '@angular/router';
import { routes } from '@app/configs/routing/app.routing';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { AuthService } from '@app/core/services/auth.service';
import { UserService } from '@app/core/services/user.service';
import { UserResolver } from '@app/core/services/resolver.user';
import { IsLoggedInResolver } from '@app/core/services/resolver.islogged';

//Environment
import { environment } from '@env/environment';
import { MainComponent } from '@app/modules/main/main.component';
import { ListComponent } from './modules/inventory/list/list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SimpleLayoutComponent,
    UserProfileComponent,
    MainComponent,
    ListComponent
  ],
  imports: [
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    // Changes Per Customer
    AngularFireModule.initializeApp(environment.fbConfig.milan),
    // AngularFireModule.initializeApp(environment.fbConfig.viabus),
    AngularFireStorageModule,
    BrowserModule,
    CoreModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    LoadingBarRouterModule,
    RouterModule.forRoot(routes, { useHash: false }),
    SharedModule,
    TranslationModule.forRoot(l10nConfig)
  ],
  providers: [
    AuthGuard, AuthService, UserService, UserResolver, IsLoggedInResolver
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor( public l10nLoader: L10nLoader ) {
    this.l10nLoader.load();
  }
 }