import { Component, OnInit, NgZone, AfterViewInit, TemplateRef } from '@angular/core';
import { AngularFireObject, AngularFireList, AngularFireDatabase } from 'angularfire2/database';
import { ShiftsService } from '@app/shared/services/shifts/shifts.service';
import { DriversService } from '@app/shared/services/drivers/drivers.service';
import { ConfigService } from '@app/shared/services/systemconfig/config.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import * as moment from 'moment';
import * as duration from 'moment-duration-format';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { map } from 'rxjs/operators';
//ag-grid n components
import { GridOptions, RowNode } from "ag-grid";
import "ag-grid-enterprise";

declare var mr: any;
@Component({
  selector: 'app-reports-operations-yesterday',
  templateUrl: './yesterday.component.html',
  styleUrls: ['./yesterday.component.css']
})
export class ReportsOperationsYesterdayComponent implements OnInit, AfterViewInit {

  public gridOptions: GridOptions;
  user: Observable<firebase.User>;
  items: AngularFireList<any[]>;
  item: AngularFireObject<any>;
  activatedRoute: AngularFireObject<any>;
  selectedContract: any[];
  model;
  columnDefs;
  rowData;
  today;
  shifts: any;
  _today;
  date: any;
  nodes: any = {};
  round: number = 1;
  selectedRows: Number;
  status: any = "";
  drivers;
  config: any;

  private rowGroupPanelShow;
  private gridApi;
  private gridColumnApi;
  private getRowNodeId;
  private getContextMenuItems;

  constructor(
    public router: Router,
    public afAuth: AngularFireAuth,
    public af: AngularFireDatabase,
    public shiftsService: ShiftsService,
    public driversService: DriversService,
    public configService: ConfigService
  ) {
    moment.locale('es');
    this.today = moment().subtract(1,'days').format('YYYYMMDD'); //TODO: Set shifts additional info
    this.date = this.today;
    this._today = moment().format('LL');
    this.getRoundTime();
    this.getSystemConfig();
    this.user = this.afAuth.authState;
    this.getColumnsDef();
    this.getGridOptions();
    
    this.getContextMenuItems = function getContextMenuItems(params) {
      var result = [
        {
          name: "Activar Ruta",
          checked: false,
          action: () => {
            let context = params.context.thisComponent;
            let item = params.node.data.key;
            let data = params.node.data;
            let vehicle = params.node.data.vehicle;
            let type = (params.node.data.type).toLowerCase();
            let geofenceBegin = params.node.data.geofenceBegin;
            let geofenceEnd = params.node.data.geofenceEnd;
            context.item = context.af.object(`/operations/routes/${context.today}/${item}`);

            if (vehicle.length > 0) {
              let date = moment();
              context.item.update({
                datetime_confirmation: date.unix(),
                active_now: true
              }).then(() => {
                context.createActiveRoute(data);
              }).then(() => {
                if (typeof (geofenceBegin) == "undefined" && typeof (geofenceEnd) == "undefined") {
                  swal({
                    type: "warning",
                    text: `La ${type} ha sido activada, pero no cuenta con geocercas. No podrá ser actualizada de manera automática.`
                  });
                }
              })
            } else {
              swal({
                title: "Asignar unidad",
                text: `Esta ${type} necesita una unidad asignada para ser activada.`,
                type: "question",
                input: "text",
                inputPlaceholder: `Asigne una unidad para esta ${type}`,
                showCancelButton: true,
                inputValidator: (value) => {
                  return !value && `No se puede activar esta ${type}!`
                }
              })
                .then((result) => {
                  console.log("input value: ", result.value);
                  if (result.value) {
                    let date = moment();
                    data.vehicle = result.value;
                    context.item.update({
                      datetime_confirmation: date.unix(),
                      active_now: true,
                      vehicle: result.value
                    }).then(() => {
                      context.createActiveRoute(data);
                    }).then(() => {
                      if (typeof (geofenceBegin) == "undefined" && typeof (geofenceEnd) == "undefined") {
                        swal({
                          type: "warning",
                          text: `La ${type} ha sido activada, pero no cuenta con geocercas. No podrá ser actualizada de manera automática.`
                        });
                      }
                    })
                  }
                })

            }


          }
        }, "separator",
        "autoSizeAll", "expandAll", "contractAll", "resetColumns", "separator", "copy", "paste", "separator", "toolPanel", "export"
      ];
      return result;
    };
  }

  ngOnInit() {

    this.rowGroupPanelShow = "always";
    this.getRowNodeId = function (data) {
      return data.key;
    };
    this.items = this.af.list(`/operations/routes/${this.today}`);

    this.items.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => ({ key: a.key, ...a.payload.val() }))
      )
    ).subscribe(items => {
      this.rowData = items;
      console.log(this.rowData);
    });
    // this.gridOptions.api.refreshView(), 0

  }

  getSystemConfig(){
    this.configService.getItemsList().snapshotChanges().pipe(
      map(actions =>
        actions.map(a => (a.payload.val() ))
      )
    ).subscribe(items => {
      this.config = items[0];
    });
  }

  ngAfterViewInit() {
    
  }

  getColumnsDef() {
    this.columnDefs = [
      { headerName: "#", checkboxSelection: true, hide: true, suppressSorting: true },
      { headerName: "Id", field: "key", hide: true },
      { headerName: "geofenceBegin", field: "geofenceBegin", hide: true },
      { headerName: "geofenceEnd", field: "geofenceEnd", hide: true },
      { headerName: "Tipo", field: "type", enableRowGroup: true },
      { headerName: "Planta", field: 'customer', enableRowGroup: true },
      { headerName: "Recorrido", field: 'name', enableRowGroup: true, filter: 'text' },
      { headerName: 'Unidad', field: 'vehicle', type: 'numericColumn', editable: false },
      { headerName: "Hora", field: 'time', enableRowGroup: true },
      {
        headerName: 'Operador', field: 'driver', editable: false,
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: params => {
          return {
            values: this.getDrivers()
          }
        }
      },
      { headerName: 'Red', field: 'driver_phone', suppressFilter: true, editable: false },
      { headerName: "Mete", field: 'task_in', enableRowGroup: true, cellRenderer: this.booleanFormatter },
      { headerName: "Saca", field: 'task_out', enableRowGroup: true, cellRenderer: this.booleanFormatter },
      { headerName: 'Clima', field: 'has_ac', enableRowGroup: true, filter: 'text', cellRenderer: this.acFormatter },
      { headerName: 'Activa', field: 'active_now', enableRowGroup: true, filter: 'text', cellRenderer: this.activeFormatter },
      {
        headerName: 'Consideración', field: 'datetime_confirmation', enableRowGroup: true,
        editable: (params) => {
          let timeSplit = params.node.data.time.split(":");
          let a = moment();
          let b = moment().set('hours', timeSplit[0]).set('minutes', timeSplit[1]);
          let allowance = parseInt(this.config.timeallowance) || 30;
          console.log("timediff, ",b.diff(a, 'minutes'));
          return b.diff(a, 'minutes') >= allowance;
        },
        enableCellChangeFlash: true, cellRenderer: this.dateRenderer
      },
      {
        headerName: 'Mete', headerClass: "centered",
        children: [
          { headerName: 'PP', field: 'datetime_pp_1', editable: false, enableCellChangeFlash: true, cellRenderer: this.dateRenderer },
          { headerName: 'PD', field: 'datetime_pd_1', editable: false, enableCellChangeFlash: true, cellRenderer: this.dateRenderer },
          { headerName: 'Personas', field: 'people_pd_1', editable: false, enableCellChangeFlash: true },
        ]
      },
      {
        headerName: 'Saca',
        children: [
          { headerName: 'Personas', field: 'people_pd_2', editable: false, enableCellChangeFlash: true },
          { headerName: 'PD', field: 'datetime_pd_2', editable: false, enableCellChangeFlash: true, cellRenderer: this.dateRenderer },
          { headerName: 'PP', field: 'datetime_pp_2', editable: false, enableCellChangeFlash: true, cellRenderer: this.dateRenderer },
        ]
      },
      {
        headerName: 'Tiempos y Distancias',
        children: [
          { headerName: 'Kms Autorizados', field: 'distance', editable: false },
          { headerName: 'Kms Reales', field: 'real_distance', editable: false, enableCellChangeFlash: true },
          { headerName: 'Estadía Autorizada', field: 'taxitime' },
          { headerName: 'Estadía real', field: 'real_taxitime', editable: false, enableCellChangeFlash: true },
        ]
      },
      { headerName: 'Fin', field: 'datetime_end', editable: false, enableCellChangeFlash: true, cellRenderer: this.dateRenderer },
      { headerName: 'round', field: 'round', filter: 'agNumberColumnFilter', type: 'numericColumn' }
    ];
  }

  getDrivers() {
    this.driversService.getItemsList().snapshotChanges().pipe(
      map(actions =>
        actions.map((a: any) =>
          (a.payload.val().name + " " + a.payload.val().lastname)
        )
      )
    ).subscribe(items => {
      this.drivers = items;
    });
    return this.drivers;
  }

  createActiveRoute(item) {

    console.log("This item: ", item);
    this.nodes = {};
    let steps = {};
    if (item.task_in) {
      let step1 = `geofenceEnter${item.geofenceBegin}`;
      let step2 = `geofenceEnter${item.geofenceEnd}`;
      steps[step1] = 'datetime_pp_1';
      steps[step2] = 'datetime_pd_1';
    }
    if (item.task_out) {
      let step3 = `geofenceEnter${item.geofenceEnd}`;
      let step4 = `geofenceExit${item.geofenceEnd}`;
      // let step5 = `geofenceExit${item.geofenceBegin}`;

      steps[step3] = 'datetime_pd_1';
      steps[step4] = 'datetime_pd_2';
      // steps[step5] = 'datetime_pp_2';
      steps['alarm'] = true;
    }
    let step6 = `ignitionOff`;
    let step7 = `operations/routes/${this.today}/${item.key}`;
    steps[step6] = 'datetime_end';
    steps["node"] = step7;
    steps["active"] = true;
    steps["route"] = item.name || "Ruta no definida";
    steps["type"] = item.type || "Ruta";
    steps["driver"] = item.driver || "Sin operador definido";
    steps["driver_phone"] = item.driver_phone || "Sin teléfono definido";
    steps["counter"] = 0;

    this.activatedRoute = this.af.object(`/operations/active/${item.vehicle}`);
    this.activatedRoute.update(steps);
  }

  getKardex() {
    const record = this.gridOptions.api.getSelectedRows()[0];
    const element = `${this.today}/${record.key}`;
    this.router.navigate(['/routes/assignment/kardex/', element]);
  }

  deleteActiveRoute(oldVehicleNode) {
    this.activatedRoute = this.af.object(oldVehicleNode);
    this.activatedRoute.remove();
  }



  getGridOptions() {
    this.gridOptions = {
      context: {
        thisComponent: this
      },
      columnDefs: this.columnDefs,
      rowData: null,
      rowSelection: 'single',
      groupUseEntireRow: true,
      rememberGroupStateWhenNewData: true,
      pagination: true,
      enableFilter: true,
      enableStatusBar: true,
      alwaysShowStatusBar: false, //status bar can be be fixed
      paginationPageSize: 50,
      onCellValueChanged: (event) => {

        let field = event.colDef.field;
        let value = event.value;
        let item = event.data.key;
        this.item = this.af.object(`/operations/routes/${this.today}/${item}`);

        if (field == "datetime_end" || field == "datetime_confirmation" || field == "datetime_pp_1" || field == "datetime_pd_1" || field == "datetime_pp_2" || field == "datetime_pd_2") {
          let date = moment().format('YYYY-MM-DD');
          let time = value;
          let changeDate = moment(date + ' ' + time);
          this.item.update({ [field]: changeDate.unix() })
        }

        if (field == "datetime_confirmation") {
          this.createActiveRoute(event.data);
          this.item.update({ active: true });
          /* let arrive = moment(item.datetime_pd_1);
          let depart = moment(value);
          let stay = depart.diff(arrive,'minutes'); */
        }

        if (field == "vehicle") {
          this.deleteActiveRoute(`operations/active/${event.oldValue}`);
          this.createActiveRoute(event.data);
          this.item.update(event.data);
        } else {
          this.item.update({ [field]: value })
        }
      },
      localeText: {
        // for filter panel
        page: 'página',
        more: 'más',
        to: 'a',
        of: 'de',
        next: 'siguiente',
        last: 'último',
        first: 'primero',
        previous: 'anterior',
        loadingOoo: 'Cargando ...',
        // for set filter
        selectAll: 'Seleccionar todo',
        searchOoo: 'Buscar ...',
        blanks: 'Blanco',
        // for number filter and text filter
        filterOoo: 'Filtrar ...',
        applyFilter: 'Aplicar filtro ...',
        // for number filter
        equals: 'igual',
        notEqual: 'no es igual',
        lessThanOrEqual: 'menor que o igual',
        greaterThanOrEqual: 'mayor que o igual',
        inRange: 'en un rango',
        lessThan: 'menor que',
        greaterThan: 'mayor que',
        // for text filter
        contains: 'contiene',
        notContains: 'no contiene',
        startsWith: 'inicia con',
        endsWith: 'termina con',
        // the header of the default group column
        group: 'grupo',
        // tool panel
        columns: 'columnas',
        rowGroupColumns: 'Columnas pivote',
        rowGroupColumnsEmptyMessage: 'por favor arrastre las columnas al grupo',
        valueColumns: 'Columnas valor',
        pivotMode: 'Modo pivote',
        groups: 'grupos',
        values: 'valores',
        pivots: 'pivotes',
        valueColumnsEmptyMessage: 'arrastre columnas para agregar',
        pivotColumnsEmptyMessage: 'arrastre aquí para pivotes',
        // other
        noRowsToShow: 'no hay información',
        // enterprise menu
        pinColumn: 'anclar columna',
        valueAggregation: 'valor agregación',
        autosizeThiscolumn: 'autojustar columna',
        autosizeAllColumns: 'autoajustar todas las columnas',
        groupBy: 'agrupar por',
        ungroupBy: 'desagrupar por',
        resetColumns: 'resetear columnas',
        expandAll: 'expandir todo',
        collapseAll: 'colapsar todo',
        toolPanel: 'panel de herramientas',
        // enterprise menu pinning
        pinLeft: 'anclar <<',
        pinRight: 'anclar >>',
        noPin: 'desanclar <>',
        // enterprise menu aggregation and status panel
        sum: 'sum',
        min: 'min',
        max: 'max',
        none: 'nada',
        count: 'conteo',
        average: 'promedio',
        // standard menu
        copy: 'copiar',
        copyWithHeaders: 'copiar con encabezados',
        ctrlC: 'ctrl-C',
        paste: 'pegar',
        ctrlV: 'ctrl-V',
        export: 'Exportar',
        csvExport: 'Exportar a CSV',
        excelExport: 'Exportar a Excel'
      }
    };
  }

  setFilterData(event) {
    let filterData = parseFloat(event.target.value);
    console.log("Filter Data: ", filterData, event)
    var roundFilterComponent = this.gridApi.getFilterInstance("round");
    var model = [filterData];
    roundFilterComponent.setModel({
      type: "equals",
      filter: filterData
    });
    this.gridApi.onFilterChanged();
  }

  public onSearchTextChange(newData: string) {
    this.gridOptions.api.setQuickFilter(newData);
  }

  public getRowHeight(params) {
    var rowIsDetailRow = params.node.level === 1;
    // return 100 when detail row, otherwise return 25
    return rowIsDetailRow ? 60 : 40;
  }

  getRoundTime() {
    this.shiftsService.getItemsList().snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ))
      .subscribe(items => {
        var myDate = new Date();
        this.shifts = items;
        items.forEach((item: any) => {
          let timeFromSplit = item.timeFrom.split(":");
          let timeToSplit = item.timeTo.split(":");
          let currentDateTime = moment();
          let timeFromDateTime = moment().set('hours', timeFromSplit[0]).set('minutes', timeFromSplit[1]);
          let timeToDateTime = moment().set('hours', timeToSplit[0]).set('minutes', timeToSplit[1]);
          if (currentDateTime.isBetween(timeFromDateTime, timeToDateTime)) {
            console.log("This one matches: ", item.name);
            this.round = item.order;
          }
        })
      });
  }

  private onBtnExport() {
    console.log("button export clicked");
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAll();

    var roundFilterComponent = this.gridApi.getFilterInstance("round");
    roundFilterComponent.setModel({
      type: "equals",
      filter: this.round
    });
    this.gridApi.onFilterChanged();

  }

  private autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }

  private booleanFormatter(params): string {
    let icon = params.value === true ? "check_circle" : "cancel";
    let color = params.value === true ? "color--success" : "color--dark";
    let cellContent: string = '';
    cellContent = `<i class="material-icons ${color}">${icon}</i>`;
    return cellContent;
  }

  private acFormatter(params): string {
    let color = params.value === true ? "color--behance" : "ag-faded";
    let cellContent: string = '';
    cellContent = `<i class="material-icons ${color}">ac_unit</i>`;
    return cellContent;
  }

  private activeFormatter(params): string {
    let color = params.value === true ? "color--success" : "color--white";
    let cellContent: string = '';
    cellContent = `<i class="material-icons ${color}">assignment_turned_in</i>`;
    return cellContent;
  }

  private dateRenderer(params) {
    if (moment.unix(parseFloat(params.value)).isValid()) {
      return moment.unix(parseFloat(params.value)).format('HH:mm');
    } else {
      return "--:--";
    }
  }

  private timeRenderer(time) {
    if (time) {
      return duration.duration(time, 'minutes').format("HH:mm")
    } else {
      return "--:--";
    }
  }

  private deviceStatusRenderer(params) {
    let iconColor = "";

    if (params.data.ignition && params.data.motion) { iconColor = "text-success"; }
    else if (params.data.ignition && !params.data.motion) { iconColor = "text-danger"; }
    else { iconColor = "text-secondary-2"; }

    const html = `<i class="icon--sm ${iconColor} icon-Bus-2"></i>`;
    return html;

  }

  private editCellRendererFunc(params) {
    return '<button class="menu-horizontal text-primary">Editar</button>';
  }

  private onSelectionChanged() {
    this.selectedRows = this.gridOptions.api.getSelectedRows().length;
  }

  private editRecord() {
    const record = this.gridOptions.api.getSelectedRows()[0];
    this.router.navigate(['/fleet/edit', record.key]);
  }

  private deleteRecord() {
    const record = this.gridOptions.api.getSelectedRows()[0];

    swal({
      title: '¿Está seguro?',
      text: `Toda la información relacionada a la ruta ${record.name} se perderá.`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: `Eliminar ${record.name}`,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      this.af.object(`/operations/routes/${this.today}/${record.key}`).remove().then(() => {
        swal({
          type: 'success',
          title: `Se ha eliminado la unidad ${record.name}.`,
          timer: 3000
        })
      })
    })
  }


}
